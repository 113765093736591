import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueCookies from "vue-cookies";
import http from "./service/axios.js";
import rules from "@/plugins/validation";
import "material-icons/iconfont/material-icons.css";
import underscore from 'underscore'
import VueTheMask from 'vue-the-mask'
import JsonExcel from "vue-json-excel";
 
import VueRouter from 'vue-router'

Vue.use(VueRouter)

Vue.use(http);
Vue.use(VueCookies);
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.prototype._ = underscore;
Vue.prototype.rules = rules;
Vue.use(VueTheMask)
Vue.filter('formatNumber', function (value, decimals = 0, thousandsSeparator = '.') {
  let result = parseFloat(value).toFixed(decimals).toString();
  if(thousandsSeparator) result = result.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
  return result
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
