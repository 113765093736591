import Vue from 'vue'
import Vuex from 'vuex'
import static_global from '@/store/modules/static_global.module'
import banner from '@/store/modules/banner.module'
import web from '@/store/modules/web.module'


Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  modules: {
    static_global,
    banner,
    web
  }
})

export default store
