import axios from "axios";
// import {generateJwtSignature, base64UrlEncode} from '@/service/hash'
import Vue from 'vue'

const prodInstance = createInstance("https://chtdigital.com/cht-api/api");
const devInstance = createInstance("http://localhost:8104/api");
// const devInstance = createInstance("http://localhost:8201/api");
function createInstance(baseURL){
    const http = axios.create({
        timeout: 30000, // Set a timeout of 5 seconds
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return http
}

export default {
    install () {
        // console.log(process.env.NODE_ENV)
        Vue.prototype.$http = process.env.NODE_ENV === 'production'? prodInstance :  devInstance
    }
}; // Check debug/build mode