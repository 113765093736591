import {
    WEB_ERROR,
    WEB_SUCCESS,
    WEB_GET
  } from "@/store/actions/web.action";
  
  import Vue from "vue";
  
  const baseURL = "/web-public";
  
  const state = {
    webList: [
      {
        name: "Mobile Legend",
        img: require("@/assets/g1.jpeg"),
        id:'mobile-legend',
        description: '<p>\
            TOP UP DIAMOND MOBILE LEGEND <br/>\
            Proses cepat 3 detik setelah pembayaran<br/>\
            Open 24 hours<br/>\
            Cara order :<br/>\
            1 . Masukkan ID + (server)<br/>\
            2. Pilih nominal diamond<br/>\
            3. Pilih metode pembayaran<br/>\
            4. Masukkan nomor Whatsapp dengan benar!<br/>\
            5. Klik beli sekarang dan lakukan pembayaran<br/>\
            6. Diamond akan masuk otomatis ke akun anda<br/>\
            UANG TRANSFER SESUAI TOTAL TAGIHAN !!!<br/>\
            </p>\
        ',
        form: {
          1: ['User ID', 'Zone ID'],
          2: {
            currency: 'Diamond',
            topup: [
              {
                name: '11',
                price: 3000
              },
              {
                name: '14',
                price: 4000
              },
              {
                name: '22 (18+4)',
                price: 7000
              },
              {
                name: '42 (38+4)',
                price: 9900
              },
              {
                name: '56 (51+5)',
                price: 13500
              },
              {
                name: '70 (64+6)',
                price: 16500
              },
              {
                name: '84 (76+8)',
                price: 19400
              },
              {
                name: '86 (78+8)',
                price: 19900
              }
            ]
          }
        }
      },
      {
        name: "Genshin Impact",
        img: require("@/assets/g2.jpeg"),
        id:'genshin-impact',
        description: '<p>\
        Tentang Genshin Impact <br/>\
        Teyvat, sebuah dunia fantasi yang bebas, <br/>\
        dinamis, dan penuh dengan kehidupan mistis, <br/>\
        dunia di mana tujuh elemen yang saling mengalir,<br/> \
        berpadu, menari dan beradu.<br/>\
        Player berperan sebagai seorang dari sepasang saudara<br/>\
         kembar yang berkelana dari dunia satu ke dunia lain. <br/>\
         Sampai sesosok dewa yang tak dikenal menghadang <br/>\
         langkahmu, merenggut saudara kembarmu, dan menyegel  <br/>\
         kekuatanmu. Karena sihirnya, kalian tertidur tak <br/>\
         sadarkan diri.Saat kesadaranmu kembali, <br/>\
         dunia di sekelilingmu bukan lagi dunia yang kalian kenal.\
        </p>',
        form: {
          1: ['User ID', 'Server'],
          2: {
            currency: 'Genesis Crystal',
            topup: [
              {
                name: '60',
                price: 12500
              },
              {
                name: '339',
                price: 65000
              },
              {
                name: '1090',
                price: 190000
              },
              {
                name: '2240',
                price: 415000
              },
              {
                name: '3880',
                price: 640000
              }
            ]
          }
        }
      },
      {
        name: "PUBG Mobile",
        img: require("@/assets/g3.jpeg"),
        id:'pubg-mobile',
        description: '<p>\
        KHUSUS SERVER INDO ! <br/>\
        SERVER GLOBAL TIDAK AKAN DIPROSES<br/>\
        Tentang PUBG Mobile:<br/>\
        PUBG Mobile adalah game mobile yang memiliki<br/>\
        100 pemain berparasut ke pulau terpencil sejauh<br/>\
        8x8 km untuk pertarungan battle royal. <br/>\
        Pemain harus menemukan dan mencari senjata, kendaraan, <br/>\
        dan persediaan mereka sendiri, dan mengalahkan setiap <br/>\
        pemain di medan pertempuran yang apik secara grafis dan <br/>\
        taktik yang memaksa pemain ke zona bermain yang menyusut. <br/>\
        Bersiaplah untuk mendarat, menjarah, dan melakukan apa <br/>\
        pun untuk bertahan dan menjadi orang terakhir yang bertahan! </p>',
        form: {
          1: ['User ID'],
          2: {
            currency: 'UC INDO',
            topup: [
              {
                name: '30',
                price: 7000
              },
              {
                name: '60',
                price: 13000
              },
              {
                name: '325',
                price: 64000
              },
              {
                name: '660',
                price: 128000
              },
              {
                name: '1800',
                price: 320000
              },
              {
                name: '3850',
                price: 630000
              }
            ]
          }
        }
      },
      {
        name: "Valorant",
        img: require("@/assets/g4.jpeg"),
        id:'valorant',
        description: '<p>\
        Top up point Valorant harga paling murah. <br/>\
        Cara topup Valorant termurah :<br/>\
        1.Masukkan Riot ID<br/>\
        2.Pilih Nominal<br/>\
        3.Masukkan jumlah<br/>\
        4.Pilih Pembayaran<br/>\
        </p>',
        form: {
          1: ['User ID'],
          2: {
            currency: 'Points',
            topup: [
              {
                name: '300',
                price: 30000
              },
              {
                name: '625',
                price: 67000
              },
              {
                name: '1650',
                price: 154000
              },
            ]
          }
        }
      },
      {
        name: "Free Fire",
        img: require("@/assets/g5.jpeg"),
        id:'free-fire',
        description: '<p>\
        Tentang Free Fire <br/>\
        Dikembangkan oleh Garena, Free Fire adalah  <br/>\
        game battle royale yang bisa dimainkan via <br/>\
        Android dan iOS. Popularitasnya sebagai game   <br/>\
        yang paling banyak diunduh menempatkan   <br/>\
        Free Fire sebagai “Best Popular Vote Game” dari <br/>\
        Google Playstore tahun 2019 lalu.\
        </p>',
        form: {
          1: ['User ID'],
          2: {
            currency: 'Diamonds',
            topup: [
              {
                name: '140',
                price: 20000
              },
              {
                name: '355',
                price: 51000
              },
              {
                name: '720',
                price: 94000
              },
            ]
          }
        }
      },
    ],
    scheduleList: [],
    webDetail: {},
    webError: "",
    webLoader: false,
    
  };
  
  const getters = {
    webList: (state) => state.webList,
    webError: (state) => state.webError,
    webLoader: (state) => state.webLoader,
  };
  
  const actions = {
    [WEB_GET]: ({ commit }) => {
      commit(WEB_GET);
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(baseURL)
          .then((resp) => {
            commit(WEB_SUCCESS, {
              field: "webList",
              data: resp.data.data,
            });
            resolve(resp.data.data);
          })
          .catch((err) => {
            // console.log(err);
            commit(WEB_SUCCESS, {
              field: "webList",
              data: [],
            });
            reject(err);
          });
      });
    }
  };
  
  const mutations = {
    [WEB_GET]: (state) => {
      state.webLoader = true;
      state.webList = [];
      state.webError = "";
    },
    [WEB_SUCCESS]: (state, resp) => {
      if (resp) {
        state[resp.field] = resp.data;
      }
      state.webError = "";
      state.webLoader = false;
    },
    [WEB_ERROR]: (state, resp) => {
      state.webError = resp.error;
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  