import Vue from "vue";
import VueRouter from "vue-router";
// import AuthGuard from "./authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/home",
    hidden: true,
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
  {
    path: "/lacak",
    name: "lacak",
    component: () => import("../views/LacakView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
  {
    path: "/game",
    name: "game",
    component: () => import("../views/GameView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
  {
    path: "/game/:id",
    name: "gameDetail",
    component: () => import("../views/GameDetailView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
  {
    path: "/daftar",
    name: "daftar",
    component: () => import("../views/DaftarView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
  {
    path: "/masuk",
    name: "masuk",
    component: () => import("../views/MasukView.vue"),
    meta: {
      hideNav: true,
      access: [],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

// router.beforeEach(AuthGuard);

export default router;
