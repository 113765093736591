<template>
  <v-footer>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <div class="text-left">
            <h3>Ikut Kami</h3>
            Dapatkan promosi dan informasi menarik lainnya dengan mengikuti
            sosial media kami
          </div>
          <v-row>
            <v-col
              cols="auto"
              v-for="(item, key, index) in sosmed"
              :key="index"
            >
              <v-btn icon large>
                <v-icon>mdi-{{ key }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <span>
            © {{ new Date().getFullYear() }} CHTDIGITAL. All Right Reserved
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterView",
  data() {
    return {
      sosmed: {
        instagram: "google.com",
        facebook: "facebook.com",
        youtube: "youtube.com",
      },
    };
  },
};
</script>
