<template>
  <v-container fill-height fluid>
    <v-row class="mx-0 white--text">
      <h1>{{ detail.name }}</h1>
      
    </v-row>
    <v-row>
      <v-col cols="auto" class="py-0">
        <v-img :src="detail.img" height="350" width="350" cover></v-img>
        <v-row class="mt-2">
            <v-col cols="auto">
              <div class="white--text text-left" v-html="detail.description"></div>
            </v-col>
        </v-row>
        
      </v-col>
      <v-col>
        <v-row class="mb-2">
          <v-card width="100%">
            <v-card-title>
              <v-avatar color="primary" class="white--text">1</v-avatar>
              <span class="px-2"> Masukkan Info Player </span>
              <v-card-text>
                <v-row>
                  <v-col v-for="(item, index) in detail.form[1]" :key="index">
                    <v-text-field solo :placeholder="item" :label="item">
                    </v-text-field
                  ></v-col>
                </v-row>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="mb-2">
          <v-card width="100%">
            <v-card-title>
              <v-avatar color="primary" class="white--text">2</v-avatar>
              <span class="px-2"> Top Up Info </span>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                    v-for="(item, index) in detail.form[2].topup"
                    :key="index"
                  >
                    <v-card
                      @click="selected = Object.assign({ bank: '' }, item)"
                      :style="{
                        background:
                          selected.name === item.name
                            ? $vuetify.theme.themes[theme].primary
                            : 'inherit',
                        color:
                          selected.name === item.name ? 'white' : 'inherit',
                      }"
                    >
                      <v-card-title class="text-subtitle-1"
                        >{{ item.name }}
                        {{ detail.form[2].currency }}</v-card-title
                      >
                      <v-card-text
                        class="text-left"
                        :style="{
                          color:
                            selected.name === item.name ? 'white' : 'inherit',
                        }"
                      >
                        Rp {{ item.price | formatNumber }},-
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-row>

        <v-row class="mb-2">
          <v-card width="100%">
            <v-card-title>
              <v-avatar color="primary" class="white--text">3</v-avatar>
              <span class="px-2"> Pilih Pembayaran </span>
              <v-card-text>
                <v-row>
                  <v-col v-for="(item, index) in payment" :key="index">
                    <v-btn
                      block
                      large
                      @click="selected.bank = item"
                      :style="{
                        background:
                          selected.bank === item
                            ? $vuetify.theme.themes[theme].primary
                            : 'inherit',
                        color: selected.bank === item ? 'white' : 'inherit',
                      }"
                    >
                      <span>
                        {{ item }}
                        <template v-if="selected.name != ''">
                          <br />
                          Rp
                          {{ (selected.price + add[index]) | formatNumber }},-
                        </template>
                      </span>
                    </v-btn></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="mb-2">
          <v-card width="100%">
            <v-card-title>
              <v-avatar color="primary" class="white--text">4</v-avatar>
              <span class="px-2"> Beli </span>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field solo placeholder="Whatsapp" label="Whatsapp">
                    </v-text-field
                  ></v-col>
                </v-row>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="mb-2">
          <v-btn block color="primary" large> BAYAR </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "GameView",
  data() {
    return {
      detail: null,
      id: null,
      selected: {
        name: "",
        bank: "",
      },
      payment: ["QRIS", "BCA", "Mandiri", "BRI", "Shopee Pay", "Gopay", "OVO"],
      add: [250, 300, 300, 300, 500, 450, 400],
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    getLoaderWeb() {
      // return this.$store.getters.webLoader;
      return false;
    },
    getWeb() {
      return this.$store.getters.webList;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.detail = this.getWeb.filter((x) => {
      return x.id === this.id;
    })[0];
  },
};
</script>
