<template>
  <v-container fill-height fluid>
    <v-row class="mx-0 white--text">
      <h1>Semua Game</h1>
    </v-row>
    <v-row>
      <template v-if="getLoaderWeb">
        <v-col v-for="index in 14" :key="index" cols="auto">
          <v-skeleton-loader
            height="250"
            width="250"
            type="image"
          ></v-skeleton-loader> </v-col
      ></template>
      <template v-else>
        <v-col v-for="(item, index) in getWeb" :key="index" cols="auto">
          <Partner :data="item"></Partner>
        </v-col>
      </template>
    </v-row>

    <!-- <iframe src="https://jcl.gacooor.com/live-draw/jakarta" width="700" height="300"></iframe> -->
  </v-container>
</template>

<script>
import Partner from "@/components/templates/Partner.vue";
// @ is an alias to /src

export default {
  name: "GameView",
  components: { Partner },
  computed: {
    getLoaderWeb() {
      // return this.$store.getters.webLoader;
      return false;
    },
    getWeb() {
      return this.$store.getters.webList;
    },
  },
};
</script>
