<template>
  <v-container fluid class="mx-8 pa-8">
    <v-row no-gutters class="my-2 white--text text-left">
      <v-col cols="12">
        <h1>Lacak Pesananmu</h1>
      </v-col>
      <v-col cols="12">
        <span>Lacak pesanan melalui nomor telepon yang terdaftar</span>
      </v-col>
      <v-col cols="12">
        <v-text-field solo> </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn> CARI PESANAN </v-btn>
      </v-col>
    </v-row>

    <!-- <iframe src="https://jcl.gacooor.com/live-draw/jakarta" width="700" height="300"></iframe> -->
  </v-container>
</template>

<script>
export default {
  name: "LacakView",
};
</script>
