import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
      theme: {
          themes: {
              light: 
                  {
                    background: '#3c4748',
                    header: '#273b40',
                    primary: '#208c8c',
                    secondary: '#cae9ea',
                    accent: '#dc3545',
                    error: '#a80100',
                    warning: '#fdc80b',
                    info: '#607d8b',
                    success: '#12c146'
                  }
          }
      }
});
// https://colors.muz.li/palette/122c91/2a6fdb/48d6d2/81e9e6/fefcbf