<template>
  <v-container fill-height fluid class="ma-0 pa-0">
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" class="px-8">
            <h2 class="my-3 white--text">MASUK</h2>
            <form class="text-left white--text">
              <v-row class="my-2" no-gutters>
                <v-col cols="12">
                  Whatsapp
                  <v-text-field solo placeholder="Whatsapp" label="Whatsapp">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  Password
                  <v-text-field solo placeholder="Password" label="Password">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn large color="primary" block>MASUK</v-btn>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7" class="ma-0 pa-0">
        <v-img
          class="ma-0 pa-0"
          src="@/assets/bg1.jpg"
          height="715"
          cover
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "DaftarView",
};
</script>
