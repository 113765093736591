var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"mx-0 white--text"},[_c('h1',[_vm._v(_vm._s(_vm.detail.name))])]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":_vm.detail.img,"height":"350","width":"350","cover":""}}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"white--text text-left",domProps:{"innerHTML":_vm._s(_vm.detail.description)}})])],1)],1),_c('v-col',[_c('v-row',{staticClass:"mb-2"},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v("1")]),_c('span',{staticClass:"px-2"},[_vm._v(" Masukkan Info Player ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.detail.form[1]),function(item,index){return _c('v-col',{key:index},[_c('v-text-field',{attrs:{"solo":"","placeholder":item,"label":item}})],1)}),1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v("2")]),_c('span',{staticClass:"px-2"},[_vm._v(" Top Up Info ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.detail.form[2].topup),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_c('v-card',{style:({
                      background:
                        _vm.selected.name === item.name
                          ? _vm.$vuetify.theme.themes[_vm.theme].primary
                          : 'inherit',
                      color:
                        _vm.selected.name === item.name ? 'white' : 'inherit',
                    }),on:{"click":function($event){_vm.selected = Object.assign({ bank: '' }, item)}}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.name)+" "+_vm._s(_vm.detail.form[2].currency))]),_c('v-card-text',{staticClass:"text-left",style:({
                        color:
                          _vm.selected.name === item.name ? 'white' : 'inherit',
                      })},[_vm._v(" Rp "+_vm._s(_vm._f("formatNumber")(item.price))+",- ")])],1)],1)}),1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v("3")]),_c('span',{staticClass:"px-2"},[_vm._v(" Pilih Pembayaran ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.payment),function(item,index){return _c('v-col',{key:index},[_c('v-btn',{style:({
                      background:
                        _vm.selected.bank === item
                          ? _vm.$vuetify.theme.themes[_vm.theme].primary
                          : 'inherit',
                      color: _vm.selected.bank === item ? 'white' : 'inherit',
                    }),attrs:{"block":"","large":""},on:{"click":function($event){_vm.selected.bank = item}}},[_c('span',[_vm._v(" "+_vm._s(item)+" "),(_vm.selected.name != '')?[_c('br'),_vm._v(" Rp "+_vm._s(_vm._f("formatNumber")((_vm.selected.price + _vm.add[index])))+",- ")]:_vm._e()],2)])],1)}),1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary"}},[_vm._v("4")]),_c('span',{staticClass:"px-2"},[_vm._v(" Beli ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","placeholder":"Whatsapp","label":"Whatsapp"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"block":"","color":"primary","large":""}},[_vm._v(" BAYAR ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }