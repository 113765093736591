<template>
  <v-container fill-height fluid>
    <v-row class="my-2">
      <Banner></Banner>
    </v-row>
    <v-row class="my-2">
      <hr width="100%" style="color: white" />
    </v-row>

    <v-row class="mx-0">
      <h2 class="white--text">Mobile Game</h2>
    </v-row>
    <v-row>
      <template v-if="getLoaderWeb">
        <v-col v-for="index in 6" :key="index" cols="auto">
          <v-skeleton-loader
            height="250"
            width="250"
            type="image"
          ></v-skeleton-loader> </v-col
      ></template>
      <template v-else>
        <v-col v-for="(item, index) in getWeb" :key="index" cols="auto">
          <Partner :data="item"></Partner>
        </v-col>
      </template>
    </v-row>

    <!-- <iframe src="https://jcl.gacooor.com/live-draw/jakarta" width="700" height="300"></iframe> -->
  </v-container>
</template>

<script>
// import { Base64 } from "js-base64";
import Partner from "@/components/templates/Partner.vue";
import Banner from "@/components/Banner.vue";
// import { BANNER_GET } from "@/store/actions/banner.action";
// import { STATIC_GET_DETAIL_ID } from "@/store/actions/static_global.action";
// @ is an alias to /src

export default {
  name: "LoginView",
  components: { Partner, Banner },
  data() {
    return {
      text: null,
      load: true,
    };
  },
  computed: {
    getBanner() {
      return this.$store.getters.bannerList;
    },
    getWeb() {
      // return ;
      return this.$store.getters.webList;
    },
    getLoaderWeb() {
      // return this.$store.getters.webLoader;
      // return true;
      return false;
    },
  },
  created() {
    // this.$store.dispatch(BANNER_GET);
    // this.$store.dispatch(WEB_GET);
    // this.$store.dispatch(STATIC_GET_DETAIL_ID, { id: 2 }).then((resp) => {
    //   this.text = Base64.decode(this.bin2string(resp[0].content.data));
    //   this.load = false;
    // });
  },
  methods: {
    bin2string(array) {
      var result = "";
      for (var i = 0; i < array.length; ++i) {
        result += String.fromCharCode(array[i]);
      }
      return result;
    },
  },
};
</script>
