import {
    STATIC_ERROR,
    STATIC_SUCCESS,
    STATIC_GET_DETAIL_ID
  } from "@/store/actions/static_global.action";
  
  import Vue from "vue";
  
  const baseURL = "/static-public";
  
  const state = {
    staticList: [],
    scheduleList: [],
    staticDetail: {},
    staticError: "",
    staticLoader: false,
    
  };
  
  const getters = {
    staticDetail: (state) => state.staticDetail,
    staticError: (state) => state.staticError,
    staticLoader: (state) => state.staticLoader,
  };
  
  const actions = {
    [STATIC_GET_DETAIL_ID]: ({ commit }, body) => {
      commit(STATIC_GET_DETAIL_ID);
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(baseURL + `/detail/${body.id}`)
          .then((resp) => {
            commit(STATIC_SUCCESS, {
              field: "staticDetail",
              data: resp.data.data,
            });
            resolve(resp.data.data);
          })
          .catch((err) => {
            // console.log(err);
            commit(STATIC_SUCCESS, {
              field: "staticDetail",
              data: {},
            });
            reject(err);
          });
      });
    }
  };
  
  const mutations = {
    [STATIC_GET_DETAIL_ID]: (state) => {
      state.staticLoader = true;
      state.staticList = [];
      state.staticError = "";
    },
    [STATIC_SUCCESS]: (state, resp) => {
      if (resp) {
        state[resp.field] = resp.data;
      }
      state.staticError = "";
      state.staticLoader = false;
    },
    [STATIC_ERROR]: (state, resp) => {
      state.staticError = resp.error;
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  