

const rules = {
    nameRules: [
        v => !!v || 'Name is required',
    ],
    resultRules: [
        v => !!v && v.length === 4 && /^\d+$/.test(v)  || 'result is required'
    ],
    resultRules2: [
        v => v === "" || /^\d+$/.test(v)  || 'result is required'
    ],
    usernameRules: [
        v => !!v || 'Username is required'
    ],
    passwordRules: [
        v => !!v || 'Password is required'
    ],
    roleRules: [
        v => !!v || 'Role is required'
    ],
    positiveNumberRules: [
        v => v > 0 || 'Number must be postive',
    ],
    ipFormat: [
        v => v === "" || v === null|| v === undefined || /^(?:25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})?$/.test(v) || 'IP format is required',
    ],

}

export default rules