<template>
  <v-container :elevation="0" color="background" dark>
    <template v-if="getLoader">
      <v-skeleton-loader type="image" tile></v-skeleton-loader
    ></template>
    <template v-else>
      <v-carousel
        v-model="model"
        hide-delimiters
        height="auto"
        width="auto"
        class="rounded-lg"
      >
        <v-carousel-item v-for="(item, index) in getBanner" :key="index">
          <v-img
            class="rounded-lg"
            :src="`data:image/png;base64,${toBase64(item.banner.data)}`"
            cover
            height="350"
          ></v-img>
          <!-- <v-img
            :src="require(`@/assets/b${index}.jpeg`)"
            cover
            height="350"
          ></v-img> -->
        </v-carousel-item>
      </v-carousel>
    </template>
  </v-container>
</template>

<script>
import { BANNER_GET } from "@/store/actions/banner.action";
export default {
  name: "HeaderComponents",
  data() {
    return {
      model: 0,
    };
  },
  computed: {
    getBanner() {
      return this.$store.getters.bannerList;
    },
    getLoader() {
      // return false;
        return this.$store.getters.bannerLoader;
    },
  },
  created() {
    this.$store.dispatch(BANNER_GET);
  },
  methods: {
    toBase64(arr) {
      //arr = new Uint8Array(arr) if it's an ArrayBuffer
      return btoa(
        arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
      );
    },
  },
};
</script>

<style>
.v-skeleton-loader--tile .v-skeleton-loader__bone {
  height: 300px;
}
</style>
