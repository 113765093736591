<template>
  <v-app-bar color="header" :elevation="4">
    <v-row>
      <v-col cols="auto">
        <router-link to="/home">
          <v-img
            src="@/assets/logo.png"
            height="40"
            width="100"
            contain
          ></v-img>
        </router-link>
      </v-col>
      <v-col cols="auto">
        <v-row no-gutters>
          <v-col cols="auto" v-for="(item, index) in navigations" :key="index">
            <router-link :to="item.link">
              <v-btn dark text>
                <v-icon>{{ item.icon }}</v-icon> {{ item.text }}
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-row no-gutters>
          <v-col
            class="mx-2"
            cols="auto"
            v-for="(item, index) in actions"
            :key="index"
          >
            <router-link :to="item.link">
              <v-btn color="primary">
                {{ item.text }}
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { BANNER_GET } from "@/store/actions/banner.action";
export default {
  name: "HeaderComponents",
  data() {
    return {
      model: 0,
      actions: [
        {
          link: "/daftar",
          text: "Daftar",
        },
        {
          link: "/masuk",
          text: "Masuk",
        },
      ],
      navigations: [
        {
          icon: "mdi-home",
          link: "/home",
          text: "beranda",
        },
        {
          icon: "mdi-magnify",
          link: "/lacak",
          text: "lacak pesananan",
        },
        {
          icon: "mdi-gamepad-variant",
          link: "/game",
          text: "game",
        },
      ],
    };
  },
  computed: {
    getBanner() {
      return this.$store.getters.bannerList;
    },
    getLoader() {
      return this.$store.getters.bannerLoader;
    },
  },
  created() {
    this.$store.dispatch(BANNER_GET);
  },
  methods: {
    toBase64(arr) {
      //arr = new Uint8Array(arr) if it's an ArrayBuffer
      return btoa(
        arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
      );
    },
  },
};
</script>
