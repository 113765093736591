import {
    BANNER_ERROR,
    BANNER_SUCCESS,
    BANNER_GET
  } from "@/store/actions/banner.action";
  
  import Vue from "vue";
  
  const baseURL = "/banner-public";
  
  const state = {
    bannerList: [],
    scheduleList: [],
    bannerDetail: {},
    bannerError: "",
    bannerLoader: false,
    
  };
  
  const getters = {
    bannerList: (state) => state.bannerList,
    bannerError: (state) => state.bannerError,
    bannerLoader: (state) => state.bannerLoader,
  };
  
  const actions = {
    [BANNER_GET]: ({ commit }) => {
      commit(BANNER_GET);
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(baseURL)
          .then((resp) => {
            commit(BANNER_SUCCESS, {
              field: "bannerList",
              data: resp.data.data,
            });
            resolve(resp.data.data);
          })
          .catch((err) => {
            // console.log(err);
            commit(BANNER_SUCCESS, {
              field: "bannerList",
              data: [],
            });
            reject(err);
          });
      });
    }
  };
  
  const mutations = {
    [BANNER_GET]: (state) => {
      state.bannerLoader = true;
      state.bannerList = [];
      state.bannerError = "";
    },
    [BANNER_SUCCESS]: (state, resp) => {
      if (resp) {
        state[resp.field] = resp.data;
      }
      state.bannerError = "";
      state.bannerLoader = false;
    },
    [BANNER_ERROR]: (state, resp) => {
      state.bannerError = resp.error;
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations,
  };
  